import * as Types from '@/app/types/row-types';


function getResourceRow(id, resource) {
    return {
        id: id,
        resourceId: resource.id,
        cssClass: [Types.RESOURCE_CLASS],
        name: `${resource.firstname} ${resource.lastname}`,
        canSelect: false,
        moveable: false,
        loaded: false,
        type: Types.RESOURCE,
        user: resource
    };
}


function getProjectRow(id, parentId, project) {
    let prefix = '';
    if (project.work_geo_restriction) {
        const filename = '/img/' + project.work_geo_restriction.toLowerCase() + '.svg';
        prefix = `<img class="wgr_xs" src="${filename}" /> `;
    }
    return {
        id: id,
        projectId: project.id,
        cssClass: [Types.PROJECT_CLASS],
        parentId: parentId,
        name: prefix + project.name,
        loaded: false,
        type: Types.PROJECT,
        lockedUntil: project.locked_until,
    };
}


function encodeHTMLEntities(text) {
    var textArea = document.createElement('textarea');
    textArea.innerText = text;
    return textArea.innerHTML;
}


function getIssueRow(id, parentId, issue) {
    const prefix = (issue.version ? '<span class="version">' + encodeHTMLEntities(issue.version) + '</span> ' : '');
    const name = encodeHTMLEntities(issue.subject);

    return {
        id: id,
        issueId: issue.id,
        cssClass: [Types.ISSUE_CLASS],
        parentId: parentId,
        name: prefix + name,
        version: issue.version,
        locked: issue.version_open === 0,
        expanded: true,
        loaded: true,
        type: Types.ISSUE,
        startDate: issue.start_date,
        dueDate: issue.due_date,
        issue: issue,
    };
}


export { getResourceRow, getProjectRow, getIssueRow }
