import * as ChartTypes from '@/app/types/chart-types';
import { hash } from '../helpers';


function getTimeEntryItem(id, rowId, date, label) {
    let item = {
        id,
        rowId,
        label,
        cssClass: [],
        date,
        time: {
            start: new Date(date + ' 02:00:00').getTime(),
            end: new Date(date + ' 22:00:00').getTime(),
        },
        cid: null,
        hash: null,
        entries: null,
        locked: false,
    };
    return item;
}


function getResourceTimeEntryItem(id, rowId, baseItem) {
    const label = Math.round(baseItem.timeEntry.hours);
    const item = getTimeEntryItem(id, rowId, baseItem.date, label);
    item.cssClass.push(ChartTypes.RESOURCE_HOURS_ITEM_CLASS);
    if (baseItem.timeEntry.real) item.cssClass.push(ChartTypes.REAL_ITEM_CLASS);
    item.cid = baseItem.cid;
    item.entries = baseItem.entries;
    // item.hash = hash(item);
    return item;
}


function getIssueTimeEntryItem(id, rowId, baseItem) {
    // const label = Math.round(timeEntry.hours * 100) / 100;
    const label = Math.round(baseItem.timeEntry.hours);
    const item = getTimeEntryItem(id, rowId, baseItem.date, label);
    item.cssClass.push(ChartTypes.ISSUE_ITEM_CLASS);
    if (baseItem.timeEntry.real) item.cssClass.push(ChartTypes.REAL_ITEM_CLASS);
    if (baseItem.locked) item.cssClass.push(ChartTypes.LOCKED_ITEM_CLASS);
    item.locked = baseItem.locked;
    item.cid = baseItem.cid;
    // item.hash = hash(item);
    return item;
}


export { getResourceTimeEntryItem, getIssueTimeEntryItem };
