import { store } from '@/store';
import tippy from 'tippy.js';
import dayjs from 'dayjs';
import * as Config from '@/config/constants';
import { getIssueLink } from '../helpers';


const MAX_ISSUES_BY_MARKER = 3;


function getMarkerTooltip(projectIssues) {
    projectIssues = Object.values(projectIssues);
    let htmlContent = '<div class="marker_tooltip">';
    let date = projectIssues[0].issues[0].due_date;
    date = dayjs(date).format('dddd D MMMM YYYY');
    htmlContent += `<h4>Jalon(s) pour ${date}</h4>`;
    for (let i in projectIssues) {
        const project = projectIssues[i];
        htmlContent += `<div class="mt-1">${project.name}</div>`;
        htmlContent += '<div class="tooltip_list">';
        for (let j in project.issues) {
            if (parseInt(j) >= MAX_ISSUES_BY_MARKER) {
                htmlContent += `<div>... +${project.issues.length - MAX_ISSUES_BY_MARKER}</div>`;
                break;
            };
            const issue = project.issues[j];
            const redmineUri = getIssueLink(issue.id);
            htmlContent += `<div><span class="version">${issue.version_name}</span>
                <a target="_blank" href="${redmineUri}">${issue.subject}</a></div>`;
        }
        htmlContent += '</div>';
    }
    htmlContent += '</div>';
    return htmlContent;
}


function getMarkerCSSClass(type) {
    let CSSClasses = ['marker'];
    if (type === 1) CSSClasses.push('marker_sales');
    if (type === 2) CSSClasses.push('marker_mixed');
    return CSSClasses;
}


function setMarkerCallback(element, data) {
    if (element._tippy) element._tippy.destroy();
    element.classList.remove('marker', 'marker_sales', 'marker_mixed');
    const endTime = data.api.time.date(data.date.rightGlobal);
    const date = endTime.format(Config.DATE_FORMAT);
    const markers = store.getters['Marker/list/markers'];
    if (!markers?.[date]) return;
    element.classList.add(...getMarkerCSSClass(markers[date].type));
    tippy(element, {
        content: getMarkerTooltip(markers[date].projects),
        interactive: true,
        appendTo: document.body
    });
}
function setMarker(element, data) {
    if (data.period !== 'day') return;
    setMarkerCallback(element, data);
    return {
        update(element, data) {
            setMarkerCallback(element, data);
        }
    }
}

export { setMarker };
