import Locale from './locale';
import * as ListColumnActions from '@/app/actions/list-column';
import * as ChartTimelineActions from '@/app/actions/chart-timeline';
import * as ChartCalendarDateActions from '@/app/actions/chart-calendar-date';
import Plugins from 'gantt-schedule-timeline-calendar/dist/plugins';


const defaultConfig = () => ({
  plugins: [
    Plugins.WeekendHighlight({
      weekdays: [6,0], className: 'weekend'
    }),
    // Plugins.ItemMovement({
    //   resizeable: true,
    //   ghostNode: false
    // })
  ],
  height: window.innerHeight - 70,
  list: {
    rows: {},
    columns: {
      data: {
        name: {
          isHTML: true,
          expander: true,
          id: 'name',
          data: 'name',
          width: 350,
          header: {
            content: 'Ressources'
          }
        },
      },
      resizer: false
    },
    toggle: {
      display: false,
    }
  },
  locale: Locale,
  // scroll: {
  //   smooth: true,
  //   xMultiplier: 1,
  //   yMultiplier: 1,
  // },
  actions: {
    'list-column-row': [
      ListColumnActions.setRow,
      ListColumnActions.setRowChildren
    ],
    'chart-calendar-date': [
      ChartCalendarDateActions.setMarker
    ],
    'chart-timeline-grid-row-block': [
      ChartTimelineActions.setGridBlock
    ],
    'chart-timeline-items-row-item': [
      ChartTimelineActions.setItem
    ],
  },
  usageStatistics: false
});


export default defaultConfig();
