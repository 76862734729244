import { store } from '@/store';
import * as RowTypes from '@/app/types/row-types';
import * as Redmine from '@/config/redmine-constants';
import * as Config from '@/config/constants';
import tippy from 'tippy.js';
import { getDPP, formatK, getDPPe } from '@/views/tree/tree-helpers';


function renderTimes(data) {
    const sold = data.sold_hours ? Math.round(data.sold_hours * 10) / 10 : 0;
    const estimated = Math.round(data.agg_estimated_hours * 10) / 10;
    const total = Math.round(data.agg_hours * 10) / 10;
    const passed = Math.round(data.agg_passed_hours * 10) / 10;
    const planned = Math.round(data.agg_planned_hours * 10) / 10;
    let html = '<table class="issue_tooltip_table"><tbody>';
    if (sold) {
        html += `
        <tr>
            <td>Temps vendu</td>
            <td class="time_figure">${sold}h</td>
            <td colspan="2">&nbsp;</td>
        </tr>`;
    }
    html += `
        <tr>
            <td>Temps estimé</td>
            <th class="time_figure">${estimated}h</th>
            <td class="time_figure">Réalisé</td>
            <td class="time_figure">Planifié</td>
        </tr>
        <tr>
            <td>Temps saisi</td>
            <th class="time_figure">${total}h</th>
            <td class="time_figure">${passed}h</td>
            <td class="time_figure">${planned}h</td>
        </tr>
`;
    html += '</tbody></table>';
    return html;
}


/* Resource row */


// function renderSkills(skills) {
//     if (!skills) return;
//     return '<u>Skills</u><br>' + skills.split('\n').map(skill => {
//         if (!skill.length) return '';
//         let skillParts = skill.split(':');
//         let skillLvl = skillParts[1] ? parseInt(skillParts[1].trim()) : 0;
//         if (skillLvl < Config.TOOLTIP_MIN_SKILL_LVL) return '';
//         let skillStars = '&starf;'.repeat(skillLvl) + '&star;'.repeat(5 - skillLvl);
//         let skillName = skillParts[0].trim();
//         return '<div>' + skillStars + ' ' + skillName + '</div>';
//     }).join('');
// }


function renderSkills(usersSkills) {
    if (!usersSkills) return;
    const skills = store.getters['Skill/skills'];
    const realUserSkills = usersSkills.filter(userSkill => userSkill.level >= Config.TOOLTIP_MIN_SKILL_LVL);
    if (!realUserSkills.length) return;
    return '<u>Skills</u><br>' + realUserSkills.map(userSkill => {
        const stars = '&starf;'.repeat(userSkill.level) + '&star;'.repeat(5 - userSkill.level);
        const skill = skills.find(skill => skill.id == userSkill.skillId) || {};
        const name = skill.name;
        const $category = `<span class="opacity5">${skill.category}</span>`;
        return '<div>' + stars + ' ' + $category + ' ' + name + '</div>';
    }).join('');
}


function setResourceRow(element, row) {
    // const skills = row.user.skills;
    // if (!skills) return;
    const htmlContent = `<h4 class="text-center">${row.name}</h4>`;
    tippy(element, {
        delay: 100,
        onCreate(instance) {
            //@ts-ignore
            instance._isRendered = false;
        },
        onShow(instance) {
            //@ts-ignore
            if (instance._isRendered) return;
            //@ts-ignore
            instance._isRendered = true;
            store.dispatch('Skill/getUsersSkills', [row.user.id]).then(usersSkills => {
                const $skills = renderSkills(usersSkills);
                if (!$skills) return instance.destroy();
                instance.setContent(htmlContent + $skills);
            });
        }
    });
}


/* Project row */


function renderProjectTooltip(projectName, project, resources) {
    let htmlContent = `<h4>${projectName}</h4>`;
    htmlContent += resources
        .filter(resource => project.dp_ids.includes(resource.id.toString()))
        .map((resource: any) => `<div><b>DP</b> ${resource.firstname} ${resource.lastname}</div>`)
        .join('');
    htmlContent += resources
        .filter(resource => project.cpa_ids.includes(resource.id.toString()))
        .map((resource: any) => `<div><b>CP</b> ${resource.firstname} ${resource.lastname}</div>`)
        .join('');
    // htmlContent += '<hr>';
    // htmlContent += renderTimes(project);

    return htmlContent;
}


function setProjectRow(element, row) {
    const projects = store.getters['Project/list/projects'];
    const resources = store.getters['Resource/list/resources'];
    const project = projects.find(project => (project.id === row.projectId));
    if (!project.dp_ids.length && !project.cpa_ids.length) return;
    tippy(element, {
        content: () => {
            return renderProjectTooltip(row.name, project, resources);
        },
    });
}


/* Issue row */


function setIssueRow(element, row) {
    let htmlContent = `<span class="float-right">#${row.issue.id}</span><h4>${row.issue.subject}</h4>`;
    htmlContent += renderTimes(row.issue);
    if (row.version) {
        const lockedHtml = row.locked ? '&#x1F512;' : '';
        const versionType = row.issue.version_type;
        if (Redmine.DPPEABLE_VERSION_TYPE.includes(versionType)) {
            const dppe = getDPPe(row.issue);
            if (dppe) {
                htmlContent += `<b class="${dppe>0 ? 'text-danger' : 'text-success'}">Avance/Retard Estimé: ${formatK(dppe)}h</b>`;
            }
        }
        htmlContent += `<div>Version: ${lockedHtml}${row.version}</div>`;
        if (versionType === Redmine.CUSTOM_FIELD_VERSION_TYPE_SALES) {
            htmlContent += `<div>Commerce: &check;</div>`;
        }
        if (Redmine.BILLABLE_VERSION_TYPE.includes(versionType)) {
            const dpp = getDPP(row.issue);
            if (dpp) {
                htmlContent += `<div class="${dpp>0 ? 'text-danger' : 'text-success'}">DPP: ${formatK(dpp)}h</div>`;
            }
        }
    }
    if (row.startDate && row.dueDate) {
        htmlContent += '<div>Dates: ' + [row.startDate, row.dueDate].join(' - ') + '</div>';
    }
    htmlContent += `<div>Progression: ${row.issue.agg_done_ratio}% ${row.issue.status}</div>`;
    if (row.issue.devops_id) {
        htmlContent += `<div>DevOps: ${row.issue.devops_id}</div>`;
    }
    htmlContent += '<div class="text-center mt-2">';
    const spaUri = `#/tree/issue/${row.issueId}`;
    htmlContent += `<a class="link_light" href="${spaUri}">Voir sur l'arbre</a>`;
    // const redmineUri = getIssueLink(row.issueId);
    // htmlContent += `<a class="link_light" target="_blank" href="${redmineUri}">Voir dans Redmine</a>`;
    if (row.issue.devops_url) {
        htmlContent += ` / <a class="link_light" target="_blank" href="${row.issue.devops_url}">DevOps</a>`;
    }
    htmlContent += '</div>';
    tippy(element, {
        content: htmlContent,
        interactive: true,
        appendTo: document.body,
        placement: 'right'
    });
}


function setRowTooltip(element, row) {
    if (element._tippy) element._tippy.destroy();
    if (row.type === RowTypes.RESOURCE) return setResourceRow(element, row);
    if (row.type === RowTypes.PROJECT) return setProjectRow(element, row);
    if (row.type === RowTypes.ISSUE) return setIssueRow(element, row);
}

export { setRowTooltip };
