import { Node, Issue, TimeEntry } from '@/app/types/interfaces';
import * as Redmine from '@/config/redmine-constants';


export function getSWISLed(estimated, raa) {
    let message = [];
    if (!parseFloat(estimated)) {
        message.push('Estimé non défini');
    }
    if (Math.round(raa * 100) > 0) {
        message.push('RAA > 0');
    }
    return message.length ? message.map(msg => `<div>${msg}</div>`).join('') : null;
}


export function getRAF(data: Issue, live = false) {
    if (!live) {
        return data.children_nb > 0 ? data.agg_raf : data.raf;
    }
    // Live mode is not used anymore, server side calculated
    if (data.status_id === 3 || data.status_id === 8) return 0;
    const sold = data.agg_sold_hours || 0;
    const estimated = data.agg_estimated_hours || 0;
    const passed = data.agg_passed_hours || 0;
    const doneRatio = data.agg_done_ratio / 100;
    if (!doneRatio || !passed) return (estimated || sold) * (1 - doneRatio);
    return (passed / doneRatio) - passed;
}


export function getDPP(data: Issue, live = false) {
    if (!live) {
        return data.children_nb > 0 ? data.agg_dpp : data.dpp;
    }
    // Live mode is not used anymore, server side calculated
    const sold = data.agg_sold_hours || 0;
    const pecc = parseFloat(data.agg_pecc_hours) || 0;
    const pecm = parseFloat(data.agg_pecm_hours) || 0;
    const pec = (sold + pecc + pecm);
    const passed = data.agg_passed_hours || 0;
    const doneRatio = data.agg_done_ratio / 100;
    if (!doneRatio) return passed;
    if (!passed) return -pec * doneRatio;
    return (passed / doneRatio) - pec;
}


/* Warning this is only usable for non node issues */
export function getDPPe(data: Issue) {
    if (data.children_nb) return 0;
    const estimated = data.agg_estimated_hours || 0;
    const passed = data.agg_passed_hours || 0;
    const doneRatio = data.agg_done_ratio / 100;
    if (!doneRatio) return passed;
    if (!passed) return -estimated * doneRatio;
    return (passed / doneRatio) - estimated;
}


export function getEntrySpeed(entry: TimeEntry, issue: Issue) {
    if (!issue.estimated_hours && !issue.sold_hours) return null;
    const ratioOffset = (entry.done_ratio_after - entry.done_ratio_before) / 100;
    const timeRatio = entry.hours / (issue.estimated_hours || issue.sold_hours);
    return ratioOffset / timeRatio;
}


export function getDAD(data: Issue, live = false) {
    if (!live) {
        return data.children_nb > 0 ? data.agg_dad : data.dad;
    }
    // Live mode is not used anymore, server side calculated
    const sold = data.agg_sold_hours || 0;
    const passed = data.agg_passed_hours || 0;
    const doneRatio = data.agg_done_ratio / 100;
    return passed - doneRatio * sold;
}


export function getDoneRatio(data: Issue, raf) {
    const passed = data.agg_passed_hours || 0;
    raf = parseFloat(raf) || 0;
    if (!passed) return 0;
    return passed/(passed+raf)*100;
}


export function getMasaoLed(data: Issue, raa) {
    const doneRatio = data.agg_done_ratio || 0;
    const aggPassed = data.agg_passed_hours || 0;
    const hours = data.hours || 0;
    const aggPlanned = data.agg_planned_hours || 0;
    const estimated = data.estimated_hours || 0;
    const aggEstimated = data.agg_estimated_hours || 0;
    const aggSold = data.agg_sold_hours || 0;
    const aggPecm = data.agg_pecm_hours || 0;
    let message = [];
    if (aggPassed > (aggEstimated * doneRatio / 100)) {
        message.push('Progression > 0 et Réalisé > Estimé * Progression');
    }
    if (Math.round(raa * 100) > 0) {
        message.push('RAA > 0');
    }
    if (aggPecm >= aggSold) {
        message.push('PECM >= Vendu');
    }
    if (aggSold > aggEstimated) {
        message.push('Vendu > Estimé');
    }
    if (aggPlanned && doneRatio === 100) {
        message.push('Planifié > 0 et Progression à 100%');
    }
    if (data.status_id === Redmine.ISSUE_STATUS_REMOVED && hours > 0 && hours !== estimated) {
        message.push('Statut = "Removed" et Réalisé/Planifié > 0 et Réalisé/Planifié != Estimé');
    }
    return message.length ? message.map(msg => `<div>${msg}</div>`).join('') : null;
}


export function formatK(value) {
    if (value === Infinity) return value;
    return Math.abs(value) > 999
        ? Math.sign(value)*Math.round(Math.abs(value)/100)/10 + 'k'
        : Math.round(Math.sign(value)*Math.abs(value)*100)/100
    ;
}


export function formatKCell(num) {
    let value = parseFloat(num);
    if (!value) return '\xa0';
    value /= 8;
    return formatK(value);
}


export function formatCell(num) {
    const value = parseFloat(num);
    return value ? Math.round(value / 8 * 100) / 100 : '\xa0';
}
export function formatRow(data: Issue) {
    return {
        agg_init_sold_hours: formatCell(data.agg_init_sold_hours),
        init_sold_hours: formatCell(data.init_sold_hours),
        agg_sold_hours: formatCell(data.agg_sold_hours),
        sold_hours: formatCell(data.sold_hours),
        agg_estimated_hours: formatCell(data.agg_estimated_hours),
        estimated_hours: formatCell(data.estimated_hours),
        agg_pecc_hours: formatCell(data.agg_pecc_hours),
        pecc_hours: formatCell(data.pecc_hours),
        agg_pecm_hours: formatCell(data.agg_pecm_hours),
        pecm_hours: formatCell(data.pecm_hours),
        // agg_raa_hours: formatCell(aggRaa),
        // swis_led: getSWISLed(data.agg_estimated_hours, aggRaa),
        agg_passed_hours: formatCell(data.agg_passed_hours),
        passed_hours: formatCell(data.passed_hours),
        agg_planned_hours: formatCell(data.agg_planned_hours),
        planned_hours: formatCell(data.planned_hours),
        masao_led: getMasaoLed(data, data.agg_raa),
    };
}


export interface TreeNode {
  data: object,
  id: number,
  label: string,
  parent: number,
  children: TreeNode[],
  path: [],
  prev?: TreeNode,
  next?: TreeNode,
};


function TreeNodeC(data) {
    this.data = data;
    this.id = data.id;
    this.label = data.subject;
    this.parent = null;
    this.children = [];
    this.path = [];
}
export function treeNodeCompare(a, b) {
    const ad = a.data;
    const bd = b.data;
    if (ad.weight && !bd.weight) return -1;
    if (!ad.weight && bd.weight) return 1;
    if (ad.weight && bd.weight) {
        if (ad.weight < bd.weight) return -1;
        if (ad.weight > bd.weight) return 1;
    }
    // if (a.children.length && !b.children.length) return -1;
    // if (!a.children.length && b.children.length) return 1;
    // if (ad.subject < bd.subject) return -1;
    // if (ad.subject > bd.subject) return 1;
    if (ad.id < bd.id) return -1;
    if (ad.id > bd.id) return 1;
    return 0;
};
TreeNodeC.prototype.sortRecursive = function () {
    this.children.sort(treeNodeCompare);
    this.children.forEach((child) => {
        child.sortRecursive();
    });
    return this;
};


function getPath(node) {
    if (!node.parent) {
        return [];
    }
    let path = [node.data.id];
    path.unshift(...getPath(node.parent));
    return path;
}
function populatePath(nodeById) {
    Object.keys(nodeById).forEach(nodeId => {
        const node = nodeById[nodeId];
        node.path = getPath(node);
    });
}


function addPrevNextLinksToNode(parent, prev = null) {
    parent.prev = prev;
    if (prev) prev.next = parent;
    prev = parent;
    for (const key in parent.children) {
        const child = parent.children[key];
        prev = addPrevNextLinksToNode(child, prev);
    }
    return prev;
}


export function getFirstNode(nodes:TreeNode[]):TreeNode {
    return Object.values(nodes[0].children)[0];
}


export function addPrevNextLinksToTree(nodes) {
    const firstNode:TreeNode = getFirstNode(nodes);
    if (!firstNode) return;
    const last = addPrevNextLinksToNode(nodes[0]);
    last.next = firstNode;
    firstNode.prev = last;
}


export function toTree(data: Node[]) {
    let nodeById = {};

    nodeById[0] = new TreeNodeC({});

    data.forEach((row: Node) => {
        nodeById[row.id.toString()] = new TreeNodeC(row);
    });
    data.forEach((row: Node) => {
        let node = nodeById[row.id.toString()];
        const parentId = node.data.parent_id || 0;
        node.parent = nodeById[parentId] || nodeById[0];
        node.parent.children.push(node);
    });

    populatePath(nodeById);
    nodeById[0].sortRecursive();
    return nodeById;
}


export function removeEmptyChildren(nodeById) {
    Object.values(nodeById).forEach((node: any) => {
        if (!node.children.length) delete node.children;
    });
    return
}


export function elementOffsetCoords(element) {
    const cellRect = element.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: cellRect.top + scrollTop,
        bottom: cellRect.bottom + scrollTop,
        left: cellRect.left + scrollLeft,
        right: cellRect.right + scrollLeft,
        width: cellRect.width
    };
}


export function groupTimeEntriesByResource(timeEntries, maxEntriesByResource = 10) {
    let indexedTies = Array.from(new Set(timeEntries.map(tie => tie.resource_id))).map(resourceId => {
        const resourceFilter = tie => tie.resource_id === resourceId;
        const entries = timeEntries.filter(resourceFilter);
        return {
            id: resourceId,
            name: timeEntries.find(resourceFilter).resource_name,
            entries: entries.slice(0, maxEntriesByResource),
            plus: entries.length - maxEntriesByResource,
            totalHours: entries.reduce((counter, entry) => +counter + +entry.hours, 0)
        };
    });
    indexedTies.sort((a, b) => {
        if (a.totalHours > b.totalHours) return -1;
        if (a.totalHours < b.totalHours) return 1;
        return 0;
    });
    return indexedTies;
}


function ellipsisText(text: string, length: number) {
    if (!text) return '';
    if (text.length < length) return text;
    return text.substring(0, length) + '&hellip;';
}
export function formatTimeEntriesForIssue(timeEntries) {
    const indexedTies = groupTimeEntriesByResource(timeEntries);
    return indexedTies.map(resource => {
        const resourceTiesRendered = resource.entries.map(tie => {
            let comments = '';
            if (tie.comments !== ''
                && tie.comments !== '.'
                && tie.comments !== 'resource manager'
            ) {
                comments = ' <span class="opacity6">' + ellipsisText(tie.comments, 40) + '</span>';
            }
            const doneRatio = tie.done_ratio_after !== null ? `<span class="opacity6">${tie.done_ratio_after}%</span>` : '';
            return `<div><span class="calendar_link" data-tie-id="${tie.id}">${tie.spent_on}</span>
                ${doneRatio}
                ${tie.hours}h${comments}</div>`;
        }).join('');
        const totalHours = resource.entries.length > 1 ? ' - ' + resource.totalHours + 'h' : '';
        const plus = resource.plus > 0 ? `<div>... +${resource.plus}</div>` : '';
        return `<div><h4>${resource.name}${totalHours}</h4>${resourceTiesRendered}${plus}</div>`;
    }).join('<hr />');
}


export function addMissingParentsToList(matchingIssues, issues) {
    for (let i = 0; i < matchingIssues.length; i++) {
        const matchingIssue = matchingIssues[i];
        const parentId = matchingIssue.parent_id;
        if (!parentId) continue;
        const parentIssue = issues.find(issue => issue.id === parentId);
        if (!parentIssue || matchingIssues.indexOf(parentIssue) !== -1) continue;
        parentIssue.isDisabled = true;
        matchingIssues.push(parentIssue);
    }
}


const TREE_PROJECT_FILTER_KEY = 'resource_manager_tree_project_filters_';
export function storeProjectFilters(projectId, filters) {
    if (!filters) return localStorage.removeItem(TREE_PROJECT_FILTER_KEY + projectId);
    localStorage.setItem(TREE_PROJECT_FILTER_KEY + projectId, JSON.stringify(filters));
}
export function getStoredProjectFilters(projectId) {
    const projectFilters = localStorage.getItem(TREE_PROJECT_FILTER_KEY + projectId);
    if (!projectFilters) return null;
    return JSON.parse(projectFilters);
}
