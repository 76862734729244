import dayjs from 'dayjs';
import tippy from 'tippy.js';
import { store } from '@/store';
import {
    setItemCssClass,
    setRowCssClass,
    getResourceIssueTimeEntries,
    getResourceDateTimeEntries,
    getColorForHours,
    isLockedUntil,
} from '@/app/helpers';
import { expandChildren, getResourceDateHours } from '@/app/gstc-helpers';
import * as Config from '@/config/constants';
import * as Redmine from '@/config/redmine-constants';
import * as RowTypes from '@/app/types/row-types';
import { TimeEntryInput, TimeModalSelection as Selection } from '@/app/types/interfaces';
import { timeEntryToDayjs } from '../calendar-helpers';


function clickGridBlock(row, date) {
    const selection: Selection = {
        date: date,
    };
    switch (row.type) {
        case RowTypes.ISSUE:
            selection.issueId = row.issueId;
        case RowTypes.PROJECT:
            selection.projectId = row.projectId;
        case RowTypes.RESOURCE:
            selection.resourceId = row.resourceId;
    }
    store.dispatch('TimeEntry/create/gridSelect', selection);
}


function ctrlClickGridBlock(data, date) {
    const resourceRow = data.row;
    const resourceId = resourceRow.resourceId;
    let resourceDateTime = getResourceDateTimeEntries(
        store.getters['TimeEntry/list/timeEntries'], resourceId, date
    );
    if (!resourceDateTime) return;
    let timeEntriesProjects = {};
    const filteredProject = store.getters['Project/show/project'];
    if (filteredProject) {
        timeEntriesProjects[filteredProject.id] = resourceDateTime.projects[filteredProject.id];
        if (!timeEntriesProjects[filteredProject.id]) return;
    } else {
        timeEntriesProjects = resourceDateTime.projects;
    }
    expandChildren(data.state, resourceRow, () => {
        for (let projectId in timeEntriesProjects) {
            const projectRow = Object.values(data.state.get(`config.list.rows`)).find(
                // @ts-ignore
                row => row.projectId == projectId && row.resourceId == resourceId
            );
            if (!projectRow) continue;
            expandChildren(data.state, projectRow);
        }
        data.state.update(`config.list.rows`, rows => rows)
    });
}

function handleShiftKeyHold() {
    if (document.onkeyup !== null) return;
    document.onkeyup = e => {
        if (e.key !== 'Shift') return;
        store.dispatch('TimeEntry/create/copy', null);
        document.onkeyup = null;
    };
}
function copy(row, date) {
    const resourceTimeEntries = getResourceIssueTimeEntries(
        store.getters['TimeEntry/list/timeEntries'],
        row.resourceId,
        row.projectId,
        row.issueId
    );
    const entry = resourceTimeEntries[date].entries[0];
    const timeEntry: Partial<TimeEntryInput> = {
        user: { id: entry.user_id },
        hours: resourceTimeEntries[date].hours,
        activityId: entry.activity_id,
        comment: entry.comments,
        futureTime: resourceTimeEntries[date].real ? Redmine.CUSTOM_FIELD_FUTURE_TIME_NO : Redmine.CUSTOM_FIELD_FUTURE_TIME_YES,
        profileId: entry.profile_id,
    };
    if (entry.project_id === Redmine.ADMINISTRATIVE_PROJECT) {
        timeEntry.status = 1;
        timeEntry.futureTime = Redmine.CUSTOM_FIELD_FUTURE_TIME_NO;
    }
    store.dispatch('TimeEntry/create/copy', timeEntry);
}
function paste(row, date, timeEntryCopied: Partial<TimeEntryInput>) {
    timeEntryCopied.issue = { id: row.issueId };
    timeEntryCopied.date = date;
    timeEntryCopied.project = { id: row.projectId };
    store.dispatch('Crud/upsertTimeEntry', timeEntryCopied).then(() => {
        store.dispatch('App/grid/refreshRowsAndItems');
    });
}
function shiftClickGridBlock(data, date) {
    if (store.getters['App/loading/loading']) return;
    const row = data.row;
    if (row.type !== RowTypes.ISSUE) return;

    const timeEntryCopied = store.getters['TimeEntry/create/timeEntryToCopy'];
    if (data.item) {
        if (timeEntryCopied) return;
        copy(row, date);
        handleShiftKeyHold();
        return;
    }

    if (!timeEntryCopied) return;
    if (row.resourceId !== timeEntryCopied.user.id) return;
    paste(row, date, timeEntryCopied);
}


function setGridBlockClick(element, data, date) {
    const row = data.row;
    const itemLocked = data?.item?.locked || false;
    const readonlyLimitDate = dayjs().subtract(Config.PASSED_DAYS_BEFORE_READONLY, 'day').startOf('day').unix();
    const timestampDate = dayjs(date).unix();
    const lockedByDateLimit = timestampDate < readonlyLimitDate;
    const readonly = itemLocked || lockedByDateLimit || row.locked || isLockedUntil(date, row.lockedUntil);
    element.classList.toggle('readonly', !!readonly);

    element.onclick = (e) => {
        if (e.ctrlKey || e.metaKey) return ctrlClickGridBlock(data, date);
        if (e.shiftKey) return readonly || shiftClickGridBlock(data, date);
        clickGridBlock(row, date);
    };
}


function getIdFromData(row) {
    const mapping = {
        [RowTypes.ISSUE]: row.issueId,
        [RowTypes.PROJECT]: row.projectId,
        [RowTypes.RESOURCE]: row.resourceId,
    };
    return mapping[row.type];
}
function setGridBlockCallback(element, data) {
    const endTime = data.api.time.date(data.time.rightGlobal);
    const date = endTime.format(Config.DATE_FORMAT);
    const row = data.row;
    // const newHash = hash([row, date]);
    // if (element.dataset.hash === newHash) return;
    // element.dataset.hash = newHash;
    const publicHoliday = store.getters['TimeEntry/list/publicHolidays']?.[row.user?.country]?.[date];
    let cssClasses = row.cssClass.slice();
    if (publicHoliday) cssClasses.push(RowTypes.PUBLIC_HOLIDAY_CLASS);
    setRowCssClass(element, cssClasses);
    element.style.backgroundColor = null;
    if (row.type === RowTypes.RESOURCE) {
        let dayTime = getResourceDateTimeEntries(
            store.getters['TimeEntry/list/timeEntries'],
            row.resourceId,
            date
        );
        if (dayTime) {
            const dayHours = getResourceDateHours(dayTime);
            element.style.backgroundColor = getColorForHours(dayHours, dayTime.leavesHours);
        }
    }
    setGridBlockClick(element, data, date);
}
function setGridBlock(element, data) {
    setGridBlockCallback(element, data);
    return {
        update(element, data) {
            setGridBlockCallback(element, data);
        }
    };
}


function getResourceItemTooltip(data) {
    const dateTimeEntries = getResourceDateTimeEntries(
        store.getters['TimeEntry/list/timeEntries'],
        data.row.resourceId,
        data.item.date
    );
    if (!dateTimeEntries) return;
    let htmlContent = '';
    const projects = Object.values(dateTimeEntries.projects);
    htmlContent += projects
        // @ts-ignore
        .map(project => {
            const hours = Math.round(project.hours * 100) / 100;
            if (!project.project) return `<div><i>Closed project</i>: ${hours} h</div>`;
            const wgr = project.project.work_geo_restriction;
            const projectFlagImage = wgr ? `<img class="wgr_sm" src="/img/${wgr.toLowerCase()}.svg" /> ` : '';
            const projectString = projectFlagImage + project.project.name;
            let line = `${projectString}: <b>${hours} h</b>`;
            if (projects.length === 1) line = projectString;
            return `<div>${line}</div>`;
        })
        .join('');
    if (projects.length === 1) {
        htmlContent += '<ul class="tooltip_list">';
        htmlContent += dateTimeEntries.entries
            .map(entry => `<li>${entry.issue_name}: <b>${Math.round(entry.hours * 100) / 100} h</b></li>`)
            .join('');
        htmlContent += '</ul>';
    }
    htmlContent += '<div class="tooltip_foot_text">Ctrl + clic pour voir les tâches</div>';
    return htmlContent;
}


const ellipsis = (input) => input.length > 200 ? `${input.substring(0, 200)}...` : input;
function getIssueItemTooltip(data) {
    const row = data.row;
    const date = data.item.date;
    const locked = data.item.locked;
    const issueTimeEntries = getResourceIssueTimeEntries(
        store.getters['TimeEntry/list/timeEntries'],
        row.resourceId,
        row.projectId,
        row.issueId
    )?.[date]?.entries;
    if (!issueTimeEntries) return;
    let htmlContent = '';
    for (let i in issueTimeEntries) {
        const entry = issueTimeEntries[i];
        const datejs = dayjs(timeEntryToDayjs(entry).toISOString());
        let entryContent = '';
        if (entry.start_time) {
            entryContent += `<div class="float-right">Heure de début: ${datejs.format(Config.TIME_FORMAT)}</div>`;
        }
        const realContent = entry.future_time !== Redmine.CUSTOM_FIELD_FUTURE_TIME_YES
            ? ' <span class="real_checkmark">&check;</span>' : '';
        entryContent += `<div>Temps: <b>${Math.round(entry.hours * 100) / 100} h</b>${realContent}</div>`;
        if (entry.comments && entry.comments !== '.' && entry.comments !== Redmine.DEFAULT_ISSUE_COMMENT) {
            entryContent += `<i>${ellipsis(entry.comments)}</i>`;
        }
        htmlContent += '<div class="mb-2">' + entryContent + '</div>';
    }
    if (!locked) {
        htmlContent += '<div class="tooltip_foot_text">Shift + clic pour copier</div>';
    }
    return htmlContent;
}


function setItemTooltip(element, data) {
    if (element._tippy) element._tippy.destroy();
    let htmlContent = '';
    if (data.row.type === RowTypes.RESOURCE) {
        htmlContent = getResourceItemTooltip(data);
    }
    if (data.row.type === RowTypes.ISSUE) {
        htmlContent = getIssueItemTooltip(data);
    }
    if (htmlContent === '') return;
    tippy(element, {
        content: htmlContent
    });
}
function setItemCallback(element, data) {
    // if (element.dataset.hash === data.item.hash) return;
    // element.dataset.hash = data.item.hash;
    const endTime = data.api.time.date(data.item.time.start);
    const date = endTime.format(Config.DATE_FORMAT);
    setItemCssClass(element, data.item.cssClass);
    setGridBlockClick(element, data, date);
    setItemTooltip(element, data);
}
function setItem(element, data) {
    setItemCallback(element, data);
    return {
        update(element, data) {
            setItemCallback(element, data);
        }
    }
}


export { setGridBlock, setItem };
