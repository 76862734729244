import GSTC from 'gantt-schedule-timeline-calendar';
import { store } from '@/store';
import { getResourceRow, getProjectRow, getIssueRow } from './converters/list-column-row';
import { getResourceTimeEntryItem, getIssueTimeEntryItem } from './converters/chart-timeline';
import { getAssignedProjects, getResourceIssueTimeEntries } from './helpers';
import * as RowTypes from '@/app/types/row-types';
import defaultConfig from '@/app/default-config';


function createCalendar() {
    let config = defaultConfig;

    // @ts-ignore
    const state = GSTC.api.stateFromConfig(config);
    // @ts-ignore
    return GSTC({
        element: document.getElementById('resource_manager_calendar'),
        state
    });
}


function updateResourceRows(resources, GSTC) {
    GSTC.update('config.list.rows', {});
    GSTC.update('config.list.rows', rows => {
        rows = {};
        let rowIndex = 1;
        for (const resource of resources) {
            rows[rowIndex] = getResourceRow(rowIndex++, resource);
        }
        // v- Fixes the last rows collapse bug -v
        rows[rowIndex] = { id: rowIndex, type: RowTypes.FAKE, cssClass: [RowTypes.FAKE_CLASS] };

        return rows;
    });
}


function getResourceDateHours(resourceDateTime) {
    const project = store.getters['Project/show/project'];
    const aggregateOnProject = store.getters['Project/show/aggregateOnProject'];
    const filterOnSales = store.getters['Project/show/filterOnSales'];
    if (aggregateOnProject) {
        if (project) {
            if (resourceDateTime.projects.hasOwnProperty(project.id)) {
                return resourceDateTime.projects[project.id].hours;
            } else {
                return null;
            }
        } else if (filterOnSales) {
            return resourceDateTime.salesHours;
        }
    }
    return resourceDateTime.hours;
}


function resourceTimeEntriesToItems(timeEntries, rows) {
    let items = {};
    let itemId = 0;

    for (let rowId in rows) {
        if (rows[rowId].type !== RowTypes.RESOURCE) continue;
        let resourceId = rows[rowId].resourceId;
        if (timeEntries.hasOwnProperty(resourceId)) {
            for (let date in timeEntries[resourceId].dates) {
                let dayTime = timeEntries[resourceId].dates[date];
                let timeEntry = { hours: null, real: dayTime.real };
                timeEntry.hours = getResourceDateHours(dayTime);
                const baseItem = {
                    timeEntry,
                    date,
                    cid: resourceId,
                    entries: dayTime.entries.map(entry => [entry.issue_id, entry.hours])
                };
                items[itemId] = getResourceTimeEntryItem(itemId, rowId, baseItem);
                itemId++;
            }
        }
    }

    return items;
}
function updateResourceItems(timeEntries, GSTC) {
    let rows = GSTC.get('config.list.rows');
    GSTC.update('config.chart.items', items => resourceTimeEntriesToItems(timeEntries, rows));
}


function updateIssueItems(timeEntries, GSTC) {
    let rows = GSTC.get('config.list.rows');
    // @ts-ignore
    let issueRows = Object.values(rows).filter(row => row.type === RowTypes.ISSUE);
    addIssueItems(timeEntries, GSTC, issueRows);
}


function getProjectsForResource(resourceId) {
    const projects = store.getters['Project/list/projects'];
    const resourceTimeEntries = store.getters['TimeEntry/list/timeEntries'][resourceId];
    const aggregateOnProject = store.getters['Project/show/aggregateOnProject'];
    const filterOnSales = store.getters['Project/show/filterOnSales'];

    if (aggregateOnProject) {
        if (!resourceTimeEntries) return [];
        const projectsTE = resourceTimeEntries.projects;
        const resourceProjects = projects.filter(project => projectsTE.hasOwnProperty(project.id));
        if (!filterOnSales) return resourceProjects;
        return resourceProjects.filter(project => projectsTE[project.id].sales);
    }
    return getAssignedProjects(projects, [resourceId]);
}


function addProjectRows(GSTC, resourceRow, afterExpand = (() => { })) {
    GSTC.update(`config.list.rows`, rows => {
        const resourceId = resourceRow.resourceId;
        const projectsToShow = getProjectsForResource(resourceId);
        if (!projectsToShow.length) return rows;

        for (const project of projectsToShow) {
            const rowId = Object.keys(rows).length + 1;
            rows[rowId] = getProjectRow(rowId, resourceRow.id, project);
            rows[rowId].resourceId = resourceId;
            rows[rowId].user = resourceRow.user;
        }

        return rows;
    });
    afterExpand();
}


function addIssueItems(timeEntries, GSTC, issueRows) {
    // Issue items
    GSTC.update('config.chart.items', items => {
        let itemId = Object.keys(items).length;

        for (let i in issueRows) {
            let issueRow = issueRows[i];

            // Issue TimeEntry items
            let issueTimeEntries = getResourceIssueTimeEntries(
                timeEntries,
                issueRow.resourceId,
                issueRow.projectId,
                issueRow.issueId
            );
            if (issueTimeEntries === null) continue;
            for (let date in issueTimeEntries) {
                const timeEntry = issueTimeEntries[date];
                const locked = !!timeEntry.entries[0].locked;
                const baseItem = {
                    timeEntry,
                    date,
                    cid: issueRow.projectId,
                    locked,
                }
                items[itemId++] = getIssueTimeEntryItem(itemId, issueRow.id, baseItem);
            }
        }

        return items;
    });
}


function linkParentIssueRows(issueRows) {
    for (let id in issueRows) {
        if (!issueRows[id].issue.parent_id) continue;
        // @ts-ignore
        let parentIssueRowId = Object.values(issueRows).find(issueRow => issueRow.issueId === issueRows[id].issue.parent_id).id;
        issueRows[id].parentId = parentIssueRowId;
    }
}


function addIssueRowsAndItems(GSTC, parentRow, projectId = null) {
    const resourceId = parentRow.resourceId;
    projectId = projectId ? projectId : parentRow.projectId;

    let timeEntries = store.getters['TimeEntry/list/timeEntries'];
    let payload = {
        userId: resourceId,
        projectId: projectId,
        issueIds: []
    };
    let projectTimeEntries = [];
    if (timeEntries[resourceId] && timeEntries[resourceId].projects[projectId]) {
        projectTimeEntries = timeEntries[resourceId].projects[projectId].issues;
        payload.issueIds = Object.keys(projectTimeEntries).map(issueId => parseInt(issueId));
    }

    store.dispatch('Issue/list/getList', payload).then(issues => {
        // Issue rows
        let issueRows = [];
        GSTC.update('config.list.rows', rows => {
            if (issues.length === 0) {
                return rows;
            }

            for (const issue of issues) {
                const rowId = Object.keys(rows).length + 1;
                rows[rowId] = getIssueRow(rowId, parentRow.id, issue);
                rows[rowId].resourceId = resourceId;
                rows[rowId].projectId = projectId;
                rows[rowId].user = parentRow.user;
                rows[rowId].lockedUntil = parentRow.lockedUntil;
                issueRows.push(rows[rowId]);
            }
            linkParentIssueRows(issueRows);

            return rows;
        });

        // Issue items
        addIssueItems(timeEntries, GSTC, issueRows);
    });
}


function expandChildren(GSTC, row, afterExpand = (() => { })) {
    row.expanded = true;
    if (row.loaded) return afterExpand();
    row.loaded = true;

    if (row.type === RowTypes.RESOURCE) {
        let project = store.getters['Project/show/project'];
        if (project) {
            addIssueRowsAndItems(GSTC, row, project.id);
            return;
        }
        addProjectRows(GSTC, row, afterExpand);
        return;
    }
    if (row.type === RowTypes.PROJECT) {
        addIssueRowsAndItems(GSTC, row);
    }
}


export {
    createCalendar,
    getResourceDateHours,
    updateResourceRows,
    updateResourceItems,
    updateIssueItems,
    addProjectRows,
    addIssueRowsAndItems,
    expandChildren,
};
