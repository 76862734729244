import { setRowCssClass } from '@/app/helpers';
import { expandChildren } from '@/app/gstc-helpers';
import { setRowTooltip } from './list-column-tooltips';


function setRowChildren(element, data) {
  element.onclick = () => {
    const row = data.state.get(`config.list.rows.${data.rowId}`);
    if (row.loaded) return;
    expandChildren(data.state, row);
  };
}


function setRowCallback(element, data) {
  const row = data.state.get(`config.list.rows.${data.rowId}`);
  if (!row) return;
  setRowCssClass(element, row.cssClass);
  setRowTooltip(element, row);
  element.classList.toggle('loaded', row.loaded);
}
function setRow(element, data) {
  setRowCallback(element, data);
  return {
      update(element, data) {
          setRowCallback(element, data);
      }
  };
}


export { setRow, setRowChildren };
