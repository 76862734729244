import dayjs from 'dayjs';
import Multiselect from 'vue-multiselect';
import tippy from 'tippy.js';
import { mapGetters } from 'vuex';
import {
    // filterResourceBySkill,
    userIsConsultant,
    filterResourceByResources,
    filterResourceByProject,
    filterResourceByProjectTimeEntries,
    filterResourceBySales,
    filterResourceByName,
    userIsMemberOfProject,
    userIsAtLeastDP,
    filterResourceByUserSkills
} from '@/app/helpers';
import {
    createCalendar,
    updateResourceRows,
    updateResourceItems,
    updateIssueItems,
} from '@/app/gstc-helpers';
import * as Config from '@/config/constants';
// @ts-ignore
const TimeModal = () => import(/* webpackChunkName: "timemodal" */ '@/views/time-modal/TimeModal.vue');

let GSTC;

function getDefaultData() {
    return {
        startDate: dayjs().subtract(7, 'day'),
        selectedResources: [],
        searchedSkill: null,
        selectedProject: null,
        selectedSkills: null,
        selectedUsersSkills: [],
        resourcesFiltered: [],
        sortedCategories: JSON.parse(localStorage.getItem(Config.SORTED_CATEGORIES_KEY)) || [],
    };
}


export default {
    name: 'grid',
    data: getDefaultData,
    components: { TimeModal, Multiselect },
    computed: {
        // @ts-ignore
        ...mapGetters({
            issues: 'Issue/list/issues',
            resources: 'Resource/list/resources',
            user: 'Resource/auth/user',
            timeEntries: 'TimeEntry/list/timeEntries',
            gridSelection: 'TimeEntry/create/gridSelection',
            gridRefreshCounter: 'App/grid/gridRefreshCounter',
            groups: 'Group/edit/groups',
            skills: 'Skill/skills',
        }),
        projects() {
            const projects = this.$store.getters["Project/list/projects"];
            if (userIsAtLeastDP(this.user)) return projects;
            return projects.filter(project => userIsMemberOfProject(this.user, project));
        },
        endDate: function () {
            return this.startDate.add(Config.DAYS_RANGE, 'day');
        },
        timeRange: function () {
            return {
                from: this.startDate.format(Config.DATE_FORMAT),
                to: this.endDate.format(Config.DATE_FORMAT)
            }
        },
        aggregateOnProject: {
            get() {
                return this.$store.getters['Project/show/aggregateOnProject'];
            },
            set(value) {
                this.$store.dispatch('Project/show/setAggregateOnProject', value);
                this.submitFilters();
            }
        },
        filterOnSales: {
            get() {
                return this.$store.getters['Project/show/filterOnSales'];
            },
            set(value) {
                this.$store.dispatch('Project/show/setFilterOnSales', value);
            }
        },
        orderedSkills() {
            return this.skills.sort((a, b) => this.sortedCategories.indexOf(a.category) - this.sortedCategories.indexOf(b.category));
        },
    },

    methods: {
        updateRowsAndItems(resources, timeEntries) {
            updateResourceRows(resources, GSTC);
            updateResourceItems(timeEntries, GSTC);
        },

        submitFilters() {
            let resources = this.resources;
            if (this.selectedProject) {
                resources = this.resources.filter(resource =>
                    filterResourceByProject(resource, this.selectedProject));
                this.$store.dispatch('Project/show/setProject', {
                    project: this.selectedProject,
                    setProject: true
                });
                if (this.aggregateOnProject) {
                    resources = this.resources.filter(resource =>
                        filterResourceByProjectTimeEntries(resource, this.timeEntries, this.selectedProject));
                }
            }
            if (this.selectedResources.length > 0) {
                resources = this.resources.filter(resource =>
                    filterResourceByResources(resource, this.selectedResources));
            }
            // if (this.searchedSkill) {
            //     resources = this.resources.filter(resource =>
            //         filterResourceBySkill(resource, this.searchedSkill));
            // }
            if (this.selectedSkills) {
                resources = this.resources.filter(resource =>
                    filterResourceByUserSkills(resource, this.selectedUsersSkills));
            }
            if (this.filterOnSales) {
                resources = this.resources.filter(resource =>
                    filterResourceBySales(resource, this.timeEntries));
            }
            this.updateRowsAndItems(resources, this.timeEntries);
        },

        unsetFilters(excludingFilter) {
            const vm = this;
            const unsetFilterActions = {
                [Config.FILTER_RESOURCES]: () => {
                    vm.selectedResources = [];
                },
                [Config.FILTER_PROJECT]: () => {
                    vm.$store.dispatch('Project/show/setProject', { project: null, setProject: true });
                    vm.selectedProject = null;
                },
                // [Config.FILTER_SKILL_SEARCH]: () => {
                //     vm.searchedSkill = null;
                // },
                [Config.FILTER_SKILL]: () => {
                    vm.selectedSkills = null;
                    vm.selectedUsersSkills = [];
                },
                [Config.FILTER_ON_SALES]: () => {
                    vm.filterOnSales = false;
                },
            };
            for (let filter in unsetFilterActions) {
                if (parseInt(filter) === excludingFilter) continue;
                unsetFilterActions[filter]();
            }
        },

        resetFilters() {
            this.unsetFilters();
            this.submitFilters();
        },

        normalizeResource(resource) {
            return resource.firstname + ' ' + resource.lastname;
        },

        normalizeSkill(skill) {
            return skill.category + ' ' + skill.name;
        },

        selectResources() {
            this.unsetFilters(Config.FILTER_RESOURCES);
            this.submitFilters();
        },

        selectProject() {
            this.unsetFilters(Config.FILTER_PROJECT);
            this.submitFilters();
        },

        selectFilterOnSales() {
            this.unsetFilters(Config.FILTER_ON_SALES);
            this.submitFilters();
        },

        searchResources(search) {
            this.resourcesFiltered = search
                ? this.resources.filter(resource => filterResourceByName(resource, search))
                : this.resources;
        },

        searchSkill() {
            if (!this.searchedSkill || this.searchedSkill.length < 3) return;
            this.unsetFilters(Config.FILTER_SKILL_SEARCH);
            this.submitFilters();
        },
        selectSkill() {
            if (!this.selectedSkills.length) return this.resetFilters();
            this.unsetFilters(Config.FILTER_SKILL);
            const skillIds = this.selectedSkills.map(skill => skill.id);
            this.$store.dispatch('Skill/getUsersForSkills', skillIds).then((usersSkills) => {
                this.selectedUsersSkills = usersSkills;
                this.submitFilters();
            });
        },

        updateTimeline() {
            GSTC.update('config.chart.time', time => {
                time.from = this.startDate.valueOf();
                time.to = this.endDate.valueOf();
                return time;
            });
            this.$store.dispatch('Marker/list/getList', {
                resourceId: this.user.id,
                from: this.timeRange.from,
                to: this.timeRange.to
            }).then((markers) => {
                GSTC.update('config.chart.time', time => time);
            });
        },
        updateTimeEntries() {
            this.$store.dispatch('TimeEntry/list/getList', this.timeRange).then(() => {
                updateResourceItems(this.timeEntries, GSTC);
                updateIssueItems(this.timeEntries, GSTC);
            });
        },
        updateTimePeriod() {
            this.updateTimeline();
            this.$store.dispatch('TimeEntry/list/getList', this.timeRange).then(() => {
                this.submitFilters();
            });
        },

        closeTimeModal() {
            this.$store.dispatch('TimeEntry/create/gridSelect', null);
        },

        today() {
            this.startDate = getDefaultData().startDate;
            this.updateTimePeriod();
        },
        prevPeriod() {
            this.startDate = this.startDate.subtract(Config.DAYS_RANGE - 10, 'day');
            this.updateTimePeriod();
        },
        nextPeriod() {
            this.startDate = this.startDate.add(Config.DAYS_RANGE - 10, 'day');
            this.updateTimePeriod();
        },

        selectGroup(group) {
            const resourceIds = group.userIds.map(id => parseInt(id));
            this.selectedResources = this.resources.filter(resource => resourceIds.includes(resource.id));
            this.selectResources();
            this.$refs.selectedResources.deactivate();
        },
    },
    watch: {
        gridRefreshCounter() {
            this.updateTimeEntries();
        },
    },
    mounted() {
        tippy(this.$refs.aggregateOnProject);
        GSTC = createCalendar().state;
        this.updateTimeline();
        this.$store.dispatch('Enumeration/list/getActivities');
        this.$store.dispatch('Project/list/getList');
        this.$store.dispatch('Group/edit/getList');
        this.$store.dispatch('Skill/getList');
        this.$store.dispatch('TimeEntry/list/getList', this.timeRange).then(() => {
            if (userIsConsultant(this.user)) {
                this.selectedResources = [this.user];
            }
            this.resourcesFiltered = this.resources;
            this.submitFilters();
        });
    },
};
